import React, { PropsWithChildren, ReactElement, useEffect, useRef } from "react";
import Helmet from "../../components/Helmet";
import Hero from "../../components/Widgets/HeroMedia";
import ClubCards from "../../components/Widgets/ClubCards";
import GradientImageBanner from "../../components/Widgets/GradientImageBanner";
import ScheduleDownload from "../../components/Widgets/ScheduleDownload";
import ClubCardsHorizontal from "../../components/Widgets/ClubCardsHorizontal";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import TextAndImageBanner from "../../components/Widgets/TextAndImageBanner";
import {
    getSeoDescription,
    getSeoTitle,
    getSeoKeywords,
} from "../../shared/helpers";
import { graphql, useStaticQuery } from "gatsby";
import styles from "./index.module.scss";

interface HomeProps {
    data: any;
    pageContext: any;
}

const Home = (props: PropsWithChildren<HomeProps>): ReactElement => {
    const dialogRef = useRef(null);
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `,
    );

    const {
        data,
        relations: { try_us, locations, schedule_download },
    } = props.pageContext;

    const tryus = {
        banner_title: try_us.dataRaw.title,
        banner_text: try_us.dataRaw.subtitle,
        banner_background: try_us.dataRaw.background_image,
        banner_cta_text: try_us.dataRaw.cta_text,
        banner_cta_url: try_us.dataRaw.cta_url,
        banner_gradient_color: try_us.dataRaw.grandient_color,
        banner_text_color: try_us.dataRaw.text_color,
    };

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Home")}
                description={getSeoDescription(
                    data,
                    `This is the home page for ${site.siteMetadata.Title}`,
                )}
                keywords={getSeoKeywords(data, "")}
            />
            
            <Hero data={data} height={80} />

            <TextAndImageBanner data={{ ...data }} />
            {locations ? (
                locations.length > 1 ? (
                    <ClubCards data={locations} />
                ) : (
                    <ClubCardsHorizontal data={locations} />
                )
            ) : (
                <WarningComponent
                    template={`Home page`}
                    message={"Locations"}
                />
            )}

            {try_us.dataRaw ? (
                <GradientImageBanner data={{ ...tryus }} />
            ) : (
                <WarningComponent
                    template={`Home page`}
                    message={"Try us widget"}
                />
            )}

            {schedule_download.dataRaw ? (
                <ScheduleDownload {...schedule_download} />
            ) : (
                <WarningComponent
                    template={`Home Page`}
                    message={"ScheduleDownload widget"}
                />
            )}
        </>
    );
};

export default Home;
